/**
 * Error levels for errors
 *
 * key value pair check
 *
 * @namespace constants.errors-levels
 */
export const playerErrorLevels = {
  '14.5': 'warn',
  '403.14007': 'warn',
  '-9002': 'fatal',
  '-9003.12000': 'warn',
  '-9003.12001': 'warn',
  '-9003.12004': 'warn',
  '-9003.12005': 'warn',
  '-9003.12007': 'warn',
  '-9003.12010': 'warn',
  '-9003.12012': 'warn',
  '-9003.12013': 'warn',
  '-9003.12016': 'warn',
  '-9003.12017': 'warn',
  '7510.22': 'warn',
  '3401.321': 'warn',
  '3401.346': 'warn',
  '8300.0': 'warn',
  '8300.403': 'warn',
  '8500.403-28: 1040': 'fatal',
  '8500.403-28: 1041': 'fatal',
  '8500.503-2': 'fatal',
  '8500.1': 'warn',
  '8500.1002': 'warn',
  '8500.403-1: 1002': 'warn',
  '8500.1038': 'warn',
  '8500.403-1: 1038': 'warn',
  '8500.1039': 'warn',
  '8500.403-1: 1039': 'warn',
  '8500.1028': 'warn',
  '8500.403-1: 1028': 'warn',
  'healPlaybackFailed': 'warn',
  'healSeekAfterAds': 'warn',
  'getLocalChannelMap.503': 'fatal'
};

export const XTVAPIErrorLevels = {
  '403.403-103': 'warn',
  '403.403-108': 'warn',
  '403.403-21': 'warn',
  '403.403-22': 'warn',
  '403.403-23': 'warn',
  '403.403-24': 'warn'
};
