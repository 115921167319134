/**
 * Messages for errors
 *
 * Each entry may contain `header`, `message`, and `tvapp` fields. Error
 * details are inherited from more general errors (so, fields set in
 * `heartbeat.recordingHeartbeat` will overwrite those set in `heartbeat`. All
 * entries ultimately inherit from `generic`
 *
 * @namespace constants.errors
 */
export default {
  'generic': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00100'
  },
  'api': {
    header: 'Something went wrong',
    message: 'Please try again.'
  },
  'config': {
    header: 'Something went wrong',
    message: 'Please stop casting and try again.'
  },
  'content_restricted': {
    header: 'We are unable to cast this video',
    message: 'Disconnect from Chromecast to watch on your device.',
    tvapp: '00154'
  },
  'heartbeat': {
    header: 'Something went wrong',
    message: 'This video cannot be played at this time.'
  },
  'offline': {
    header: 'Problem with your connection',
    message: 'Make sure your Chromecast is connected to the Internet',
    tvapp: '00125'
  },
  'player': {
    header: 'Something went wrong',
    message: 'Please try again.'
  },
  'provision': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00115'
  },
  'provision.sessionNotFound': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00134'
  },
  'provision.provisionChromecast': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00170'
  },
  'provision.refreshXactChromecast': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00172'
  },
  'provision.403.403-103': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00112'
  },
  'provision.403.403-108': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00111'
  },
  'unsupported_device': {
    header: 'Playback is not supported on this type of Chromecast device',
    message: 'Disconnect from Chromecast to watch on your device.',
    tvapp: '00140'
  },
  'geofence_error': {
    header: 'We’ve got a location issue.',
    message: 'Unfortunately, we can’t play this video within this market.'
  },
  'api.getChannelMap': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00502'
  },
  'api.channelMapMissingChannelId': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00502'
  },
  'api.getTvGridChunk': {
    header: 'Something went wrong',
    message: 'Please try again.',
    tvapp: '00501'
  },
  // XVP api call
  'api.getEntityMetaDataForNewProgram': {
    header: 'Something went wrong.',
    message: 'Please try again.',
    tvapp: '00503'
  },
  // XTVAPI call
  'api.getTvListingDetail': {
    header: 'Something went wrong.',
    message: 'Please try again.',
    tvapp: '00503'
  },
  'heartbeat.tveLinearStreamHeartbeat.503': { tvapp: '00212' },
  'heartbeat.linearStreamHeartbeat.503': { tvapp: '00213' },
  'heartbeat.linearStreamHeartbeat.403.102': { tvapp: '00220' },
  'heartbeat.recordingHeartbeat.503': { tvapp: '00313' },
  'heartbeat.tveRecordingHeartbeat.503': { tvapp: '00313' },
  'heartbeat.tveVodStreamHeartbeat.503': { tvapp: '00412' },
  'heartbeat.vodStreamHeartbeat.503': { tvapp: '00413' },
  'heartbeat.vodStreamHeartbeat.403.13': { tvapp: '00417' },
  'heartbeat.vodStreamHeartbeat.403.102': { tvapp: '00420' },

  'player.vod.healSeekAfterAds': { tvapp: '00465' },
  'player.vod.healPlaybackFailed': { tvapp: '00466' },
  'player.healPlaybackAttemptFailed': { tvapp: '00467' },
  'player.linear.-3': { tvapp: '00141' },
  'player.recording.-3': { tvapp: '00141' },
  'player.vod.-3': { tvapp: '00141' },
  'player.linear.-7': { tvapp: '00141' },
  'player.recording.-7': { tvapp: '00141' },
  'player.vod.-7': { tvapp: '00141' },
  'player.linear.1': { tvapp: '00206' },
  'player.recording.1': { tvapp: '00306' },
  'player.vod.1': { tvapp: '00406' },
  'player.linear.2.1': { tvapp: '00259' },
  'player.recording.2.1': { tvapp: '00359' },
  'player.vod.2.1': { tvapp: '00459' },
  'player.linear.3': { tvapp: '00270' },
  'player.recording.3': { tvapp: '00370' },
  'player.vod.3': { tvapp: '00470' },
  'player.linear.4': { tvapp: '00271' },
  'player.recording.4': { tvapp: '00371' },
  'player.vod.4': { tvapp: '00471' },
  'player.linear.5': { tvapp: '00272' },
  'player.recording.5': { tvapp: '00372' },
  'player.vod.5': { tvapp: '00472' },
  'player.linear.6': { tvapp: '00273' },
  'player.recording.6': { tvapp: '00373' },
  'player.vod.6': { tvapp: '00473' },
  'player.linear.7': { tvapp: '00274' },
  'player.recording.7': { tvapp: '00374' },
  'player.vod.7': { tvapp: '00474' },
  'player.linear.8': { tvapp: '00275' },
  'player.recording.8': { tvapp: '00375' },
  'player.vod.8': { tvapp: '00475' },
  'player.linear.9': { tvapp: '00276' },
  'player.recording.9': { tvapp: '00376' },
  'player.vod.9': { tvapp: '00476' },
  'player.linear.10': { tvapp: '00277' },
  'player.recording.10': { tvapp: '00377' },
  'player.vod.10': { tvapp: '00477' },
  'player.linear.11': { tvapp: '00278' },
  'player.recording.11': { tvapp: '00378' },
  'player.vod.11': { tvapp: '00478' },
  'player.linear.12': { tvapp: '00279' },
  'player.recording.12': { tvapp: '00379' },
  'player.vod.12': { tvapp: '00479' },
  'player.linear.13': { tvapp: '00280' },
  'player.recording.13': { tvapp: '00380' },
  'player.vod.13': { tvapp: '00480' },
  'player.linear.14': { tvapp: '00281' },
  'player.recording.14': { tvapp: '00381' },
  'player.vod.14': { tvapp: '00481' },
  'player.linear.15': {
    tvapp: '00282',
    description: 'The browser has prevented autoplay of the video'
  },
  'player.recording.15': { tvapp: '00382' },
  'player.vod.15': { tvapp: '00482' },
  'player.linear.401': { tvapp: '00141' },
  'player.recording.401': { tvapp: '00141' },
  'player.vod.401': { tvapp: '00141' },
  'player.linear.-9003.401': { tvapp: '00107' },
  'player.recording.-9003.401': { tvapp: '00107' },
  'player.vod.-9003.401': { tvapp: '00107' },
  'player.linear.-9003.403': { tvapp: '00141' },
  'player.recording.-9003.403': { tvapp: '00141' },
  'player.vod.-9003.403': { tvapp: '00141' },
  'player.linear.-9003.500': { tvapp: '00102' },
  'player.recording.-9003.500': { tvapp: '00102' },
  'player.vod.-9003.500': { tvapp: '00102' },
  'player.linear.-9003.501': { tvapp: '00103' },
  'player.recording.-9003.501': { tvapp: '00103' },
  'player.vod.-9003.501': { tvapp: '00103' },
  'player.linear.-9003.502': { tvapp: '00104' },
  'player.recording.-9003.502': { tvapp: '00104' },
  'player.vod.-9003.502': { tvapp: '00104' },
  'player.linear.-9003.503': { tvapp: '00105' },
  'player.recording.-9003.503': { tvapp: '00105' },
  'player.vod.-9003.503': { tvapp: '00105' },
  'player.linear.-9003.504': { tvapp: '00106' },
  'player.recording.-9003.504': { tvapp: '00106' },
  'player.vod.-9003.504': { tvapp: '00106' },
  'player.recording.-9003.12000': { tvapp: '00313' },
  'player.linear.-9003.12000': { tvapp: '00209' },
  'player.vod.-9003.12000': { tvapp: '00409' },
  'player.linear.-9003.12001': { tvapp: '00301' },
  'player.recording.-9003.12001': { tvapp: '00301' },
  'player.vod.-9003.12001': { tvapp: '00301' },
  'player.linear.-9003.12003': { tvapp: '00303' },
  'player.recording.-9003.12003': { tvapp: '00303' },
  'player.vod.-9003.12003': { tvapp: '00303' },
  'player.recording.-9003.12004': { tvapp: '00309' },
  'player.linear.-9003.12004': { tvapp: '00201' },
  'player.linear.-9003.12005': { tvapp: '00108' },
  'player.recording.-9003.12005': { tvapp: '00108' },
  'player.vod.-9003.12005': { tvapp: '00108' },
  'player.7900.13': { tvapp: '00108' },
  'player.linear.7900.13': { tvapp: '00108' },
  'player.linear.-9003.12007': { tvapp: '00101' },
  'player.recording.-9003.12007': { tvapp: '00101' },
  'player.vod.-9003.12007': { tvapp: '00101' },
  'player.linear.-9003.12009': { tvapp: '00305' },
  'player.recording.-9003.12009': { tvapp: '00305' },
  'player.vod.-9003.12009': { tvapp: '00305' },
  'player.linear.-9003.12011': { tvapp: '00202' },
  'player.recording.-9003.12011': { tvapp: '00202' },
  'player.vod.-9003.12011': { tvapp: '00202' },
  'player.linear.-9003.12012': { tvapp: '00203' },
  'player.recording.-9003.12012': { tvapp: '00203' },
  'player.vod.-9003.12012': { tvapp: '00203' },
  'player.linear.-9003.12013': { tvapp: '00401' },
  'player.recording.-9003.12013': { tvapp: '00401' },
  'player.vod.-9003.12013': { tvapp: '00401' },
  'player.linear.-9003.12014': { tvapp: '00403' },
  'player.recording.-9003.12014': { tvapp: '00403' },
  'player.vod.-9003.12014': { tvapp: '00403' },
  'player.linear.-9003.12015': { tvapp: '00404' },
  'player.recording.-9003.12015': { tvapp: '00404' },
  'player.vod.-9003.12015': { tvapp: '00404' },
  'player.linear.-9003.12016': { tvapp: '00405' },
  'player.recording.-9003.12016': { tvapp: '00405' },
  'player.vod.-9003.12016': { tvapp: '00405' },
  'player.linear.-9003.12017': { tvapp: '00204' },
  'player.recording.-9003.12017': { tvapp: '00204' },
  'player.vod.-9003.12017': { tvapp: '00204' },
  'player.linear.-9003.12400': { tvapp: '00205' },
  'player.recording.-9003.12400': { tvapp: '00205' },
  'player.vod.-9003.12400': { tvapp: '00205' },
  'player.recording.-9003.12002': { tvapp: '00302' },
  'player.vod.-9003.12002': { tvapp: '00402' },
  'player.recording.-9003.12010': { tvapp: '00310' },
  'player.vod.-9003.12010': { tvapp: '00410' },
  'player.linear.500': { tvapp: '00141' },
  'player.recording.500': { tvapp: '00141' },
  'player.vod.500': { tvapp: '00141' },
  'player.linear.502': { tvapp: '00141' },
  'player.recording.502': { tvapp: '00141' },
  'player.vod.502': { tvapp: '00141' },
  'player.linear.3401.244101': { tvapp: '00284' },
  'player.linear.3401.245104': { tvapp: '00284' },
  'player.linear.3401.245105': { tvapp: '00284' },
  'player.linear.3401.245106': { tvapp: '00285' },
  'player.linear.3401.245108': { tvapp: '00285' },
  'player.linear.3401.245110': { tvapp: '00285' },
  'player.linear.3401.321': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00283'
  },
  'player.linear.3401.354': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00190'
  },
  'player.linear.8300.-3': {
    header: 'Video Not Available',
    message: 'No live events are currently airing on this channel. Please select another channel.',
    tvapp: '00252'
  },
  'player.linear.8300.0': {
    description: 'External Player auth error',
    tvapp: '00248'
  },
  'geofence_error.8300': {
    description: 'This content cannot be accessed outside of the US',
    tvapp: '00286'
  },
  'player.linear.8300.403': { tvapp: '00248' },
  'player.linear.8500.403-28: 1040': { tvapp: '00232' },
  'player.linear.8500.403-28: 1041': { tvapp: '00232' },
  'player.linear.8500.503-2': { tvapp: '00232' },
  'player.linear.8500.1': { tvapp: '00233' },
  'player.linear.8500.1002': { tvapp: '00233' },
  'player.linear.8500.403-1: 1002': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.1038': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.403-1: 1038': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.1039': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.403-1: 1039': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.1028': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.linear.8500.403-1: 1028': {
    header: 'This video isn\'t available in your area.',
    message: '',
    tvapp: '00234'
  },
  'player.startExternalTveLinearStream.403.403-12': { tvapp: '00217' },
  'player.startExternalTveLinearStream.500': { tvapp: '00216' },
  'player.startExternalTveVodStream.403.403-13': { tvapp: '00417' },

  'geofence_error.403.21': { tvapp: '00224' },
  'geofence_error.403.22': { tvapp: '00225' },
  'geofence_error.403.23': { tvapp: '00226' },
  'geofence_error.403.24': { tvapp: '00227' },
  'geofence_error.403.403-21': { tvapp: '00224' },
  'geofence_error.403.403-22': { tvapp: '00225' },
  'geofence_error.403.403-23': { tvapp: '00226' },
  'geofence_error.403.403-24': { tvapp: '00227' },

  'getLocalChannelMap.503': { tvapp: '00229' },
  'player.getLocalChannelMap.503': { tvapp: '00229' },
  'player.channelNotFound': { tvapp: '00155' },
  'player.listingNotFound': { tvapp: '00000' }
};
