import api from 'api';
import featuresTypes from 'constants/xvp-ads-types';

import WatchableModel from 'model/watchable';
import ListingModel from 'model/listing';

import watchableTypes from 'constants/watchable-types';
import SplunkLogger from '../lib/telemetry/splunk-logger';
import Session from '../lib/session';
import { selectVchipRating } from 'lib/helpers';
import XVP from '../lib/xvp';
import { senderDebugger } from '../lib/debug/sender-receiver-debug';

let channelMapPromise = null;
let channelCache = {};
const localChannels = [];

/**
 * Channel object
 *
 * @hideconstructor
 */
class ChannelModel extends WatchableModel {
  listingCache = {};
  /**
   * Load the channel map
   *
   * Response is cached, so subsequent calls will not make a network request.
   * @async
   * @return {HypergardResource[]}
   */
  static async getChannelMap() {
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);
    const sendDate = (new Date()).getTime();
    let responseTimeMs = 0;
    let logResponse = {};
    // TEMP DISABLED Channel Map promise cache check - this is done during investigation of channel map returns
    // const sendDate = (new Date()).getTime();
    // let responseTimeMs = 0;
    if (!channelMapPromise) {
      if (isXVP) {
        const channelApiParams = {
          f2m: 'include',
          include: 'subscriberAccess',
          byStreamStatus: ['Production', 'Staging']
        };

        const localTvGridChannels = await XVP.send({
          endPoint: 'getLocalTvGridChannels',
          ...channelApiParams
        });

        channelMapPromise = await XVP.send({
          endPoint: 'getEntitledTvGridChannels',
          ...channelApiParams
        }).then(async (response) => {
          senderDebugger.debugNetworkMessage('ATTEMPT JSON XVP RESPONSE RAW:', response);
          logResponse = response && response.clone ? response.clone() : response;
          const receiveDate = (new Date()).getTime();

          responseTimeMs = receiveDate - sendDate;
          return await response.json();
        }).then((response) => {
          if (response) {
            const responseValue = response;
            const entitledChannels = responseValue.filter((channel) =>
              channel.station && channel.station.subscriberAccess && channel.station.subscriberAccess.isWatchable
            ).length;
            const xboAccountId = Session.xboAccountId;
            const lastKnownEntitledCount = localStorage.getItem(`tv-entitled-channels-${xboAccountId}`);

            const channels = {
              entitled: entitledChannels,
              diff: lastKnownEntitledCount ? entitledChannels - lastKnownEntitledCount : 0,
              total: responseValue.length,
              unentitled: responseValue.length - entitledChannels
            };

            localStorage.setItem(`tv-entitled-channels-${xboAccountId}`, entitledChannels);

            const splunkObj = {
              endpoint: 'getEntitledTvGridChannels',
              xhr: {
                url: logResponse.url,
                status: logResponse.status,
                type: logResponse.type
              },
              request: {
                params: channelApiParams
              },
              responseTime: responseTimeMs,
              serviceName: 'getEntitledTvGridChannels'
            };

            SplunkLogger.onSuccess({ ...splunkObj, channels });
            return response.concat(localTvGridChannels);
          }
        }).catch((error) => {
          channelMapPromise = null;
          console.error('Cannot getEntitledTvGridChannels - Channel Map Error', error);
          // Keep in mind `getEntitledTvGridChannels` matches `getChannelMap` error code
          throw XVP.errorFormatted({
            errorType: 'getChannelMap',
            error,
            options: {
              channelId: this.channelId,
              logResponse: logResponse || error.xhr
            } });
        });
        return channelMapPromise;
      }

      channelMapPromise = api.send({
        endpoint: 'getChannelMap',
        params: {
          freetome: 'off' // TODO: Do we need to be able to change this?
        },
        suppressLogging: true
      })
        .then((response) => {
          const channelsSource = response.resource.getEmbedded('channels');
          const entitledChannels = channelsSource.map((channel) => channel.getProp('entitled')).filter(Boolean).length;
          const xboAccountId = Session.xboAccountId;
          const lastKnownEntitledCount = localStorage.getItem(`tv-entitled-channels-${xboAccountId}`);
          const channels = {
            entitled: entitledChannels,
            diff: lastKnownEntitledCount ? entitledChannels - lastKnownEntitledCount : 0,
            total: channelsSource.length,
            unentitled: channelsSource.length - entitledChannels
          };
          localStorage.setItem(`tv-entitled-channels-${xboAccountId}`, entitledChannels);
          SplunkLogger.onSuccess({ ...response, channels });
          return channelsSource;
        })
        .catch((error) => {
          channelMapPromise = null;
          throw error;
        });
    }

    return channelMapPromise;
  }

  /**
   * Reset caches
   *
   * Resets the channel map cache
   */
  static resetCache() {
    channelMapPromise = null;
    channelCache = {};
  }

  /**
   * Load channel based on channel ID
   * @async
   * @param {string} channelId - Channel ID to load
   * @return {ChannelModel}
   */
  static async load(channelId) {
    this.channelId = channelId.toString();
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);

    if (!channelCache[channelId]) {
      const channels = await ChannelModel.getChannelMap();
      let channelResource;
      senderDebugger.sendDebugMessage( 'LOAD Channel isXVP', {
        isXVP: isXVP,
        channelCount: channels && channels.length }
      );
      if (channels.length > 0 && isXVP) {
        channelResource = channels.find((ch) => {
          const chanId = ch.channelId.toString();
          return chanId === this.channelId;
        });
      } else {
        channelResource = channels.find((ch) => ch.getProp('channelId') === channelId);
        if (!channelResource) {
          console.warn(`Attempting to find channelId=${ channelId } in local channel map`);
          const localChannel = await ChannelModel.getLocalChannelById(channelId);
          if (localChannel) {
            channelResource = channels.find((ch) => ch.getProp('companyId') === localChannel.companyId && ch.getProp('isTve'));
          }
        }
      }

      if (!channelResource) {
        senderDebugger.debugErrorMessage(`[XVP][Channel][Load] Cannot find channelId=${ channelId } in channel map`, {
          channelId: channelId,
          channelMapCount: channels && channels.length
        });

        throw XVP.errorFormatted({
          errorType: 'channelMapMissingChannelId',
          options: {
            channelId: channelId
          } });
      }

      channelCache[channelId] = await ChannelModel.fromResource(channelResource);

      if (!channelCache[channelId]) {
        senderDebugger.debugErrorMessage(`[Channel] Cannot find channelId=${ channelId } in channel Cache[channelId] map`, {
          channelId: channelId,
          channelMapCount: channels.length
        });
        console.error(`Cannot find channel cache channelId=${ channelId } in channel map`);
        throw XVP.errorFormatted( {
          errorType: 'channelMapMissingChannelId',
          options: {
            channelId: this.channelId
          } });
      }
    }
    return channelCache[channelId];
  }

  /**
   * Load channel based on channel self link
   * @async
   * @param {string} url - Self link URL to search for
   * @return {ChannelModel}
   */
  static async loadFromSelfLink(url) {
    senderDebugger.sendDebugMessage(`loadFromSelfLink for url: ${url}`);
    const channels = await ChannelModel.getChannelMap();
    // Parses only hyperguard resource - not XVP supported parsing
    const channelResource = channels.find((ch) => ch.getFirstAction('self').getRawActionUrl() === url);
    const channelId = channelResource.getProp('channelId');
    senderDebugger.sendDebugMessage(`[Channel] loadFromSelfLink Found channel: ${channelId}`, {
      channelId: channelId,
      channelResource: channelResource,
      channelCache: `channelCache count: ${Object.keys(channelCache).length}`,
      channelExistsInCache: channelCache[channelId]
    });
    if (!channelCache[channelId]) {
      channelCache[channelId] = await ChannelModel.fromResource(channelResource);
    }

    return channelCache[channelId];
  }
  /**
   * loadFromChannelId - XVP method - load a channel id from channel map
   * @param {object} jsonResource  - json from XVP channel map
   * @return {ChannelModel}
   *
   **/
  static async loadFromChannelId(jsonResource = {}) {
    const channelId = jsonResource.channelLinkId && jsonResource.channelLinkId.split('_')[1];

    if (!channelCache[channelId]) {
      channelCache[channelId] = await ChannelModel.fromResource(jsonResource);
    }

    return channelCache[channelId];
  }

  static async _propertiesFromResource(resource) {
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);

    if (isXVP) {
      return await ChannelModel._propertiesFromXvp(resource);
    }

    const props = resource.getProps();
    return {
      ...await super._propertiesFromResource(resource),
      companyCallSign: props['branchOf/company/callSign'],
      callSign: props.callSign,
      channelId: props.channelId,
      entitled: props.entitled,
      streamId: props.streamId,
      isTveFlag: props.isTve,
      number: props.number,
      enforceParentalControlsOnChannel: props.enforceParentalControlsOnChannel,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult,
      stationId: props.stationId,
      companyId: props.companyId,
      logo: resource.getFirstAction ? resource.getFirstAction('logo') : '',
      contentProvider: {
        name: props.callSign
      },
      tveVariant: resource.getFirstAction ? resource.getFirstAction('tveVariant').getRawActionUrl() : 'not yet'
    };
  }

  static async _propertiesFromXvp(props) {
    const locators = props.locators || [];
    const station = props.station || {};
    const companyAssociations = station && station.companyAssociations || [];
    const playableStream = locators.find((stream) => stream.format === 'HLS') || locators[0];
    const productContexts = station.productContexts || [];

    let isTve = false;
    const locator = locators.length && locators[0];
    const prodContext = productContexts.length && productContexts[0];
    isTve = locator && locator.namespaceTitle && locator.namespaceTitle.endsWith('TVE') || prodContext.type === 'TVE';

    return {
      callSign: props.onScreenCallsign,
      castInfo: {
        chromecast: true
      },
      channelId: props.channelId,
      channelLinkId: `link_${props.channelId}`,
      companyCallSign: companyAssociations.length && companyAssociations[0].displayName,
      companyId: companyAssociations.length && companyAssociations[0].companyId,
      entitled: station.subscriberAccess ? station.subscriberAccess.isWatchable : false,
      isTveFlag: isTve,
      logo: `entityId=${station.stationId}`,
      number: props.channelNumber,
      productContexts: productContexts,
      stationId: station.stationId,
      streamId: playableStream.streamId,
      streams: locators,
      contentProvider: {
        name: props.onScreenCallsign
      },
      tveVariant: station.stationId,
      _type: watchableTypes.Channel
    };
  }

  /**
   * Build channel from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ChannelModel}
   */
  static async fromResource(resource) {
    if (resource.getFirstAction) {
      return new ChannelModel(await ChannelModel._propertiesFromResource(resource));
    }
    senderDebugger.sendDebugMessage(`[XVP][CHANNEL][fromResource] resource ${Object.keys(channelCache).length}`,
      { fromResource: resource }
    );
    return new ChannelModel(await ChannelModel._propertiesFromXvp(resource));
  }

  /**
   * Get listing for a specific time
   *
   * Returns the listing that is airing at the specified time
   *
   * @param {number} time - Time to get listing for
   * @return {ListingModel}
   */
  async getListingAt(time) {
    let listingResources = null;
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);
    const timeStart = Math.floor(time / 3600e3) * 3600e3; // Round time down to nearest hour
    let logResponse = {};
    const listingFinder = (listing) => (listing.startTime <= time && listing.endTime > time);

    if (!this.listingCache[timeStart]) {
      if (isXVP) {
        const searchStart = new Date(timeStart);
        const searchEnd = new Date(timeStart + 36e5);
        const stationParamObj = Object.assign({}, {
          endPoint: 'getListingsByDate',
          startTime: searchStart,
          endTime: searchEnd,
          stationId: this.stationId
        });

        const sendDate = (new Date()).getTime();
        let responseTimeMs = 0;
        listingResources = await XVP.send(stationParamObj).then(async (response) => {
          logResponse = response.clone();
          const receiveDate = (new Date()).getTime();

          responseTimeMs = receiveDate - sendDate;
          const splunkObj = {
            endpoint: 'getListingsByDate',
            xhr: {
              url: logResponse.url,
              status: logResponse.status,
              type: logResponse.type
            },
            request: {
              params: stationParamObj
            },
            responseTime: responseTimeMs,
            serviceName: 'getListingsByDate'
          };
          SplunkLogger.onSuccess({ ...splunkObj, stationParamObj });
          return await response.json();
        }).then((listingsResponse) => {
          return listingsResponse.map((listing) => ({
            channelLinkId: `link_${this.channelId}`,
            endTime: new Date(listing.endTime).getTime(),
            isXVP: true,
            listingId: listing.listingId,
            program: listing.program,
            rating: selectVchipRating(listing.contentRatings || []),
            streams: this.streams,
            startTime: new Date(listing.startTime).getTime(),
            title: listing.title,
            _type: watchableTypes.Listing
          }));
        }).catch((error) => {
          senderDebugger.debugErrorMessage(`[XVP][Channel][GetListingAt] ERROR getListingsByDate station: ${this.stationId}`, error);
          throw XVP.errorFormatted( {
            errorType: 'getTvGridChunks',
            error,
            options: {
              channelId: this.accountChannelId || this.channelId,
              stationId: this.stationId,
              stationParamObj: stationParamObj,
              logResponse
            } });
        });

        senderDebugger.debugNetworkMessage('[XVP][CHANNEL][getListingAt] 3 XVP RESPONSE LIST ARRAY: ', {
          listingResources: listingResources
        });
      } else {
        const response = await api.send({
          endpoint: 'getTvGridChunk',
          params: {
            startTime: timeStart,
            hours: 1,
            channelIds: this.accountChannelId || this.channelId
          }
        }).catch((error) => {
          senderDebugger.debugErrorMessage(`[XTVAPI][Channel][GetListingAt] ERROR getTvGridChunk station: ${this.stationId}`, error);

          throw XVP.errorFormatted({
            errorType: 'getTvGridChunks',
            error,
            options: {
              stationId: this.stationId,
              channelId: this.accountChannelId || this.channelId,
              startTime: timeStart
            }
          });
        });

        const channel = response.resource.getEmbedded('channels')[0];
        listingResources = channel.getEmbedded('listings');
      }

      this.listingCache[timeStart] = await Promise.all(listingResources.map(ListingModel.fromResource));
      this.listingCache[timeStart].forEach((listing) => listing.channel = this);
    }
    senderDebugger.debugNetworkMessage('[CHANNEL][getListingAt] from the listingCache: ', {
      listingCache: this.listingCache[timeStart].length
    });

    return this.listingCache[timeStart].find(listingFinder);
  }

  static async getLocalChannelMap(networkAffiliateCompanyId = null) {
    let params = null;
    if (networkAffiliateCompanyId) {
      params = { networkAffiliateCompanyId };
    }

    const localChannelResponse = await api.send({
      endpoint: 'getLocalChannelMap',
      params
    });

    if (!localChannelResponse) {
      return;
    }

    const localChannelResources = localChannelResponse.resource.getEmbedded('channels');
    if (localChannelResources && localChannelResources.length) {
      localChannelResources.forEach((channelResource)=> {
        const localStreamResources = channelResource.getEmbedded('stream');
        const localStreams = [];

        localStreamResources.forEach((stream)=> {
          localStreams.push(Object.assign(stream.getProps(), {
            contentUrl: stream.getFirstAction('contentUrl').getActionUrl('self')
          }));
        });

        localChannels.push(Object.assign(channelResource.getProps(), { streams: localStreams }));
      });
    }
    return localChannels;
  }

  static async getLocalChannelById(channelId) {
    let localChannel = localChannels.find((ch) => ch.channelId === channelId);
    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap();
      localChannel = localChannelList.find((ch) => ch.channelId === channelId);

      if (!localChannel) {
        console.error(`Cannot find channelId=${ channelId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }

  async getLocalChannelByCompanyId() {
    const companyId = this.companyId || this.channel.companyId;
    let localChannel = localChannels.find((ch) => ch.companyId === companyId);

    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap(companyId);
      localChannel = localChannelList.find((ch) => ch.companyId === companyId);

      if (!localChannel) {
        console.error(`Cannot find companyId=${ companyId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }

  hasGeofencedLocatorType() {
    const locators = this.locators || this.streams || [];

    if (locators.length) {
      return !!locators.find((locator) => locator.type === 'GeoFenced');
    }
    return false;
  }

  async canStreamTve() {
    return await XVP.send({
      endPoint: 'canStreamTve',
      streamId: this.streamId || '',
      serviceZoneType: (this.stream || {}).serviceZoneType || '',
      locatorType: (this.stream || {}).format || ''
    });
  }
}

WatchableModel.addType(ChannelModel, ({ _type }) =>
  _type === watchableTypes.Channel);

export default ChannelModel;
